import React from "react";

import LandingUpper from "./LandingUpper";

const LandingPg = () => {
  return (
    <>
      <LandingUpper />
    </>
  );
};

export default LandingPg;
